<template>
    <div>
        <update-view-header
            headline="Neue Anfrage erstellen"
            button-text="Zurück zur Übersicht"
            button-path="/"
            class="mb-6"
        />

        <steps :model="steps" class="mb-6"/>

        <div class="surface-card surface-border border-1 p-6 pb-5">
            <router-view
                @prevPage="prevPage"
                @nextPage="nextPage"
                v-model="request"
                :errors="errors"
            />
        </div>
    </div>
</template>

<script>
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";
    import Steps from 'primevue/steps';
    import Button from "primevue/button/Button";

    export default {
        components: {
            UpdateViewHeader,
            Button,
            Steps
        },
        methods: {
            nextPage() {
                let index = this.steps.map(function (e) {
                    return e.to;
                }).indexOf(this.$route.path);

                if (index === 2) {
                    let request = Object.assign({}, this.request);
                    this.$sendToApi('requests', request, (entity, status, errors) => {
                        if(status > 299) {
                            this.errors = errors;
                            if(this.errors.hasOwnProperty('effectCategoryPriorities')) {
                                this.$router.push(this.steps[1].to);
                            }
                        } else {
                            this.request = Object.assign({}, entity);
                            this.$router.push('/requests/' + this.request.id);
                        }
                    });
                } else {
                    this.$router.push(this.steps[index + 1].to);
                }
            },
            prevPage() {
                let index = this.steps.map(function (e) {
                    return e.to;
                }).indexOf(this.$route.path);
                this.$router.push(this.steps[index - 1].to);
            }
        },
        data() {
            return {
                company: {},
                request: {
                    impactBhiCategory: {},
                    target: {},
                    workingEnvironment: {},
                    typeCategory: {},
                    effectCategoryPriorities: {},
                },
                errors: {},
                steps: [
                    {
                        label: 'Interne Daten',
                        to: '/requests/create/intern'
                    },
                    {
                        label: 'Kategorie, Ziel & Wirkung',
                        to: '/requests/create/categories'
                    },
                    {
                        label: 'Organisatorisches',
                        to: '/requests/create/organization'
                    }
                ],
            }
        }
    }
</script>
